import React from 'react';
import { graphql } from 'gatsby';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import { PortalGrid } from '@components/portals/portalGrid';
import { useVenueQuery } from '@querys/useVenueQuery';
import { OtherEvents } from '@components/portals/otherEvents';
import { searchClient } from '@lib/algolia';
import { VenueFilters } from '../components/nav/filters/venueFilters';
import { useSiteContext } from '@lib/context';

const VenuePortalTemplate = ({ data: { page } }) => {
  const { seo, config } = page || {};
  const { nodes } = useVenueQuery();
  const { state } = useSiteContext();

  //filter out nodes whose location is not in state.filters.locations
  const filteredNodes = nodes?.filter((node) => {
    if (state?.filters?.locations?.length > 0) {
      return state?.filters?.locations?.includes(node?.location);
    }
    return true;
  });

  //filter nodes by location alphabetically
  const sortedNodes = filteredNodes?.sort((a, b) => {
    if (a?.location < b?.location) {
      return -1;
    }
    if (a?.location > b?.location) {
      return 1;
    }
    return 0;
  });

  return (
    <Layout config={config}>
      {/* SEO */}
      <PageMeta {...seo} />
      {/* Venue Grid */}
      <InstantSearch searchClient={searchClient} indexName='venues'>
        <Configure hitsPerPage={600} />
        <VenueFilters />
        <PortalGrid nodes={sortedNodes} />
      </InstantSearch>
      {/* Other Events */}
      <OtherEvents />
    </Layout>
  );
};

export default VenuePortalTemplate;

export const simpleVenuePortalQuery = graphql`
  query standardVenuePortalQuery($slug: String!) {
    page: sanityVenuePortal(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      config: pageConfig {
        reverseHeader
        unpinnedHeader
      }
    }
  }
`;
